.section {
  border-radius: 20px;
  background-color: #fff;
  padding: 30px 20px;
  height: 100%;

  @media screen and (min-width: 1036px) {
    min-height: 310px;
  }
}

.fullHeight {
  height: 100%;
}
