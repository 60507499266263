.proteinReason {
  border-radius: 10px;
  padding: 40px;
}
.proteinReasonText {
  line-height: 1.8;
  border-bottom: 2px solid #f0f0f0;
}
.proteinMarkpen {
  color: #ff9b00;
}
.proteinNote {
  line-height: 1.8;
}
.proteinReasonImagePc {
  max-width: 580px;
}
.timeCookingImage {
  max-width: 400px;
}
@media screen and (max-width: 980px) {
  .proteinReason {
    padding: 30px 20px;
  }

  .timeCookingImage {
    max-width: 100%;
    height: auto;
  }
}
