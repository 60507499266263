.wrapper {
  max-width: 750px;
  width: 100%;
}

.productDetail {
  padding: 14px;
  dl {
    border-bottom: 1px solid #c8c8c8;
    img {
      width: 100%;
      height: auto;
    }
    dt {
      padding: 20px 0;
    }
    dd {
      padding-bottom: 20px;
    }
    &:last-child {
      border-bottom-width: 0;
    }
  }
}

.image {
  display: inline-block;
  vertical-align: middle;
}
