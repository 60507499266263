.proteinVoice {
  height: 100%;
  border-radius: 10px;
  border: 2px solid #f0f0f0;
  padding: 30px 30px 40px;
}
.proteinVoiceTitle {
  line-height: 2;
  span {
    background: linear-gradient(transparent 80%, #fac83c 0, #fac83c 100%, transparent 0);
  }
}
.proteinMarkpen {
  color: #ff9b00;
}
.image {
  display: inline-block;
  vertical-align: middle;
}
