.container {
  display: flex;
  &.iconContainer {
    align-items: flex-start;
    margin-bottom: 20px;
  }
}
.container:last-child {
  margin-bottom: 0;
}

.icon {
  margin-right: 10px;
  line-height: 1;
}
