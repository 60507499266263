.graphBlock {
  border-radius: 10px;
  padding: 20px;
}
.fullImages {
  width: 100%;
  height: auto;
}
.proteinMarkpen {
  color: #ff9b00;
}
.proteinNutrition {
  border-radius: 10px;
  border: 2px solid #f0f0f0;
  padding-top: 15px;
  padding-bottom: 40px;
}
.proteinNutritionTitle {
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 15px;
}
.proteinNote {
  line-height: 1.8;
}

@media screen and (min-width: 1036px) {
  .graphBlock {
    padding: 40px;
  }
}

@media screen and (max-width: 980px) {
  .proteinNutrition {
    padding: 15px;
  }
}
